import React from "react"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import * as style from "./TitleContent.module.scss"

const TitleContent = ({ data, lang, smallTitle, smallText }) => {
  const { title, content } = data
  return (
    <div
      className={`mt-5 ${style.titleContent} ${
        smallText ? style.smallText : ""
      }`}
    >
      {smallTitle ? (
        <div className={`ming`}>
          <CustomMarkdown html={title} color={`copper`} />
        </div>
      ) : (
        <h2 className={`ming`}>
          <CustomMarkdown html={title} color={`copper`} />
        </h2>
      )}
      <div className={`${smallTitle ? "" : "ming"}`}>
        <CustomMarkdown html={content}></CustomMarkdown>
      </div>
    </div>
  )
}

export default TitleContent
