import React from "react"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Img from "../Img"
import * as style from "./Advantages.module.scss"

const Advantages = ({ data, lang }) => {
  const { advantage } = data
  return (
    <div className={`${style.advantages} flex flex-wrap mt-3`}>
      {advantage.map((ad, index) => (
        <div
          key={index}
          className={`ming px-lg-5 col-lg-6 col-12 mt-4 ${style.advantage}`}
        >
          <Img image={ad.icon} className={`mb-2 ${style.image}`} />
          <h3 className={``}>{ad.title}</h3>
          <CustomMarkdown
            html={ad.content}
            classnames={`text-lg-start text-center`}
          />
        </div>
      ))}
    </div>
  )
}

export default Advantages
