import React, { useState } from "react"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Accordion from "../Accordion"
import Button from "../Button"
import Img from "../Img"
import * as style from "./InsurancePlans.module.scss"

const InsurancePlans = ({ data, lang }) => {
  const { insuranceCategory, ctas, bottomText } = data

  const [selected, setSelected] = useState(insuranceCategory[0])
  const [opening, setOpening] = useState(0)

  const accordionFormat = (image, title, content) => ({
    image: image,
    title: title,
    markdown: content,
  })

  return (
    <>
      <div
        className={`bg-mint d-lg-block d-none mt-4 py-4 soft-corners ${style.insurancePlans}`}
      >
        <div
          className={`flex text-center justify-content-around flex-lg-no-wrap flex-wrap`}
        >
          {insuranceCategory?.map((cat, index) => (
            <div
              key={index}
              tabIndex={index}
              className={`${style.tag} ${
                selected.title === cat.title ? style.selected : ""
              } round-corners flex-lg-1`}
              onClick={() => setSelected(cat)}
              onKeyDown={() => setSelected(cat)}
            >
              {cat.title}
            </div>
          ))}
        </div>

        {selected && (
          <div className={`d-grid ${style.planDetails} my-5`}>
            <div className={`${style.left} flex align-items-center`}>
              <Img
                image={selected.categoryImage}
                className={`me-2 ${style.image}`}
              />

              <h2 className={`teal mb-0`}>{selected.title}</h2>
            </div>
            <div className={`${style.right} text-start ming`}>
              <CustomMarkdown html={selected.categoryContent} />
            </div>
          </div>
        )}

        <div className={``}>
          {bottomText && <h2 className={`ming`}>{bottomText}</h2>}
          {ctas && ctas.length > 0 && (
            <div className={`mt-4 flex justify-content-center`}>
              {ctas.map((cta, index) => (
                <div key={index} className={`mx-4`}>
                  <Button
                    lang={lang}
                    type={cta.template}
                    text={cta.buttonLabel}
                    url={cta.url}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="d-lg-none d-block ming">
        {insuranceCategory?.map((item, index) => {
          return (
            <Accordion
              lang={lang}
              key={index}
              item={accordionFormat(
                item.categoryImage,
                item.title,
                item.categoryContent
              )}
              index={index}
              opening={opening}
              setOpening={setOpening}
            />
          )
        })}

        {bottomText && <h2 className={`ming mt-5`}>{bottomText}</h2>}
        {ctas && ctas.length > 0 && (
          <div className={`mt-4 flex justify-content-center`}>
            {ctas.map((cta, index) => (
              <div key={index} className={`mx-4`}>
                <Button
                  lang={lang}
                  type={cta.template}
                  text={cta.buttonLabel}
                  url={cta.url}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default InsurancePlans
