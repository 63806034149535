import "./Process.scss"

import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Button from "../Button"
import Dash from "../Dash"

const Process = ({ props, lang, additionalClass }) => {
  const { title, ctas, processStep } = props

  const circle = "../../images/process/circle.webp"
  return (
    <section className={`process  ${additionalClass ? additionalClass : ""}`}>
      <div className="top">
        <h2 className="title ming">
          <CustomMarkdown html={title} color="copper"></CustomMarkdown>
        </h2>
        <Dash className="dash" />
      </div>
      <div className="middle container">
        <div className="row">
          {processStep.slice(0, 3).map((item, index) => {
            return (
              <div
                key={`process-step-${index}`}
                className={`col-lg-4 step step-${index + 1}`}
              >
                <StaticImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className={`circle ${(index % 2 > 0 && "reflect") || ""}`}
                  src={circle}
                  alt="alea"
                />
                <span className="number ming lora-SemiBold">{index + 1}</span>
                {index < 2 && <div className="line"></div>}
              </div>
            )
          })}
        </div>
        <div className="row rowContent">
          {processStep.slice(0, 3).map((item, index) => {
            return (
              <div
                key={`process-content-${index}`}
                className="col-lg-4 content mt-0"
              >
                <h3 className="title ming">{item.title}</h3>
                <div className="text">{item.content}</div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="end">
        {ctas &&
          ctas.slice(0, 2).map((item, index) => {
            return (
              <Button
                lang={lang}
                key={`process-btn-${index}`}
                type={item.template}
                text={item.buttonLabel}
                url={item.url}
                buttonId={
                  index === 0
                    ? "home_process_book_call"
                    : "home_process_get_free_quote"
                }
              />
            )
          })}
      </div>
    </section>
  )
}

export default Process
