import React, { useState } from "react"
import * as style from "./brand.module.scss"
import Header from "../components/brand/Header"
import Layout from "../components/Layout"
import TitleContent from "../components/referral/TitleContent"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
} from "../plugins/common"
import InsurancePlans from "../components/brand/InsurancePlans"
import Advantages from "../components/brand/Advantages"
import loadable from "@loadable/component"
import Process from "../components/homepage/Process"
import Partners from "../components/brand/Partners"
import { StaticImage } from "gatsby-plugin-image"
const FaqInPage = loadable(() => import("../components/FaqInPage"))
const BrandTemplate = ({ pageContext, location }) => {
  const leftShape = "../images/brand/left-shape.png"
  const rightShape = "../images/brand/right-shape.png"

  const { data, form, general } = pageContext
  const { logo, dynamicContent, freeQuote, meta } = data

  const [metaData, setMetaData] = useState(meta)

  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(logo)
  const aleaToken = general.aleaToken
  const header = dynamicContent.find(
    c => c.strapi_component === "partners.header"
  )
  const process = pageContext.general.allStrapiGeneral.nodes[0].process
  const quoteForm = {
    ...form.freeQuote,
    ...freeQuote,
  }

  return (
    <Layout
      props={pageContext.general}
      meta={metaData}
      localizations={getAllLocales(data.localizations, data.locale)}
      location={location}
      lang={lang}
      ogImage={ogImage}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={quoteForm.enableFooter ? quoteForm : null}
      footerEvent={`partners_page_submit`}
    >
      <Header
        data={header}
        headerForm={quoteForm}
        location={location}
        lang={lang}
        token={aleaToken}
        logo={logo}
      />
      <div
        className={`text-center mx-auto my-5 position-relative overflow-x-hidden ${style.body}`}
      >
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src={leftShape}
          alt={"Healthy Matters"}
          className={`position-absolute d-lg-block d-none ${style.leftShape}`}
          layout="constrained"
        />
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src={rightShape}
          alt={"Healthy Matters"}
          className={`position-absolute d-lg-block d-none ${style.rightShape}`}
          layout="constrained"
        />
        {dynamicContent.map((section, index) => {
          // if (section.strapi_component === "partners.header") return
          return (
            (section.strapi_component === "layout.title-content" && (
              <TitleContent key={index} data={section} lang={lang} />
            )) ||
            (section.strapi_component === "partners.insurance-plan" && (
              <InsurancePlans key={index} data={section} lang={lang} />
            )) ||
            (section.strapi_component === "partners.advantage-list" && (
              <Advantages key={index} data={section} lang={lang} />
            )) ||
            (section.strapi_component === "partners.learn-partners" && (
              <div key={index} className={` mt-5 mx-lg-5 `}>
                <Partners data={section} lang={lang} />
              </div>
            )) ||
            (section.strapi_component === "layout.faq" && (
              <FaqInPage
                key={index}
                faq={section.questionAnswer}
                addtionalClass={`brand`}
                lang={lang}
                meta={metaData}
                setMeta={setMetaData}
              />
            )) ||
            (section.strapi_component === "partners.process" &&
              section.enableProcess &&
              process && (
                <Process
                  key={index}
                  props={process}
                  lang={lang}
                  additionalClass={"nonHomepage"}
                />
              ))
          )
        })}
      </div>
    </Layout>
  )
}
export default BrandTemplate
