// extracted by mini-css-extract-plugin
export var alertRed = "InsurancePlans-module--alert-red--rXjGy";
export var blackText = "InsurancePlans-module--black-text--4gViJ";
export var copper = "InsurancePlans-module--copper--kZsoW";
export var darkGrey = "InsurancePlans-module--dark-grey--K+3NK";
export var greyPlaceholder = "InsurancePlans-module--grey-placeholder--Smg5e";
export var greyText = "InsurancePlans-module--grey-text--pYSL5";
export var image = "InsurancePlans-module--image--K6o13";
export var insurancePlans = "InsurancePlans-module--insurancePlans--HaBrb";
export var left = "InsurancePlans-module--left--d9OoJ";
export var lightGrey = "InsurancePlans-module--light-grey--ayoIB";
export var ming = "InsurancePlans-module--ming--fQniM";
export var mingHover2 = "InsurancePlans-module--ming-hover-2--njVji";
export var planDetails = "InsurancePlans-module--planDetails--c3iyW";
export var right = "InsurancePlans-module--right--OkzxQ";
export var selected = "InsurancePlans-module--selected--SWS+w";
export var tag = "InsurancePlans-module--tag--AXcFp";
export var teal = "InsurancePlans-module--teal--N9Sms";
export var white = "InsurancePlans-module--white--6Zto2";
export var womenRed = "InsurancePlans-module--women-red--naFKf";
export var womenSharp = "InsurancePlans-module--women-sharp--hF2mO";