import "./Accordion.scss"

import React, { useState } from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import { mdiChevronDown, mdiChevronUp } from "@mdi/js"
import Icon from "@mdi/react"

import curve from "../images/for-team/accordion-curve.webp"
import { getGatsbyImage } from "../plugins/common"
import { CustomMarkdown } from "../plugins/sanitizehtml"
import Button from "./Button"
import Img from "./Img"

const Accordion = props => {
  const { image, title, content, url, subtitle, bulletContent, markdown } =
    props.item
  const lang = props.lang
  const [opened, setOpened] = useState(
    props.alwaysOpen
      ? true
      : props.opening
      ? props.opening === props.index
      : false
  )
  // const setOpening = props.setOpening
  // const defaultOpen = props.defaultOpen ?? false
  let chevron = opened ? mdiChevronUp : mdiChevronDown
  let hideRow =
    typeof props.opening === "number"
      ? props.opening === props.index
        ? ""
        : "d-none"
      : opened
      ? ""
      : "d-none"

  // const imageFile = getGatsbyImage(image)

  const specialHighlightColor = props.specialColor ?? "teal"

  return (
    <div className="accordion position-relative">
      <div className="row item g-0 col-12 justify-content-center  px-4">
        <div
          className="header row ps-0 col-12 justify-content-center"
          role="button"
          tabIndex={0}
          onClick={() => {
            if (props.alwaysOpen) return
            props.setOpening
              ? props.setOpening(props.index)
              : setOpened(!opened)
          }}
          onKeyDown={() => {
            if (props.alwaysOpen) return
            props.setOpening
              ? props.setOpening(props.index)
              : setOpened(!opened)
          }}
        >
          <div className="order-lg-0 order-1 col-2 col-lg-12 px-0 imgContainer">
            {/* <GatsbyImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="img"
              image={imageFile}
              alt="alea"
            /> */}
            <Img image={image} className={`img`} />
          </div>

          <h3 className="title order-lg-1 order-sm-0 order-lg-0 col col-lg-12">
            {title}
          </h3>

          {!props.alwaysOpen && (
            <div className=" chevron-btn col-1 text-end order-3 d-lg-none  ">
              <Icon path={chevron} size={1.5} />
            </div>
          )}
        </div>
        <div
          className={`row g-0 col-12 order-4 order-sm-4 d-lg-block ${hideRow} `}
        >
          {bulletContent && (
            <div
              className="bulletArea position-relative"
              style={{ backgroundImage: `url(${curve})` }}
            >
              <p className="bulletContent">{bulletContent}</p>
            </div>
          )}
          {subtitle && <div className="content ps-lg-0">{subtitle}</div>}
          {content && (
            <div className="content ps-lg-0">
              <CustomMarkdown
                html={content}
                color={`${specialHighlightColor} openSans-Bold`}
              ></CustomMarkdown>
            </div>
          )}
          {markdown && (
            <div className="content ps-lg-0">
              <CustomMarkdown html={markdown}></CustomMarkdown>
            </div>
          )}
          <div className="btn  text-lg-center text-start ps-lg-0">
            {url && (
              <Button
                lang={lang}
                type="readMore"
                text={props.readMoreLabel}
                url={url}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accordion
